/*!
 * Start Bootstrap - Agency v5.0.5 (https://startbootstrap.com/template-overviews/agency)
 * Copyright 2013-2019 Start Bootstrap
 * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap-agency/blob/master/LICENSE)
 */
body {
  overflow-x: hidden;
  font-family: 'Raleway-Medium';
}

.intro-heading,
.ara,
.event-title,
.program-title,
.section-heading {
  font-family: 'Raleway-Black';
}

p {
  line-height: 1.75;
}

a {
  color: #ffe701;
  font-weight: 700;
}

a.active,
a:active,
a:focus,
a:hover {
  color: #fec503;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  font-family: 'Raleway-Medium', 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

section {
  padding: 100px 0;
}

section h2.section-heading {
  font-size: 40px;
  margin-top: 0;
  margin-bottom: 15px;
}

section h3.section-subheading {
  font-size: 16px;
  font-weight: 400;
  font-style: italic;
  margin-bottom: 75px;
  text-transform: none;
}

@media (min-width: 768px) {
  section {
    padding: 150px 0;
  }
}

::selection {
  background: #fed136;
  text-shadow: none;
}

img::selection {
  background: transparent;
}

img::-moz-selection {
  background: transparent;
}

body {
  -webkit-tap-highlight-color: #fed136;
}

/** Font Family **/
@font-face {
  font-family: 'Raleway-black';
  src: url('../fonts/Raleway-Black.ttf');
}

@font-face {
  font-family: 'Raleway-Medium';
  src: url('../fonts/Raleway-Medium.ttf');
}

@font-face {
  font-family: 'Raleway-Italic';
  src: url('../fonts/Raleway-Italic.ttf');
}
@font-face {
  font-family: 'Raleway-Regular';
  src: url('../fonts/Raleway-Regular.ttf');
}

@font-face {
  font-family: 'Raleway-Light';
  src: url('../fonts/Raleway-Light.ttf');
}

.text-primary {
  color: #ffe701 !important;
}

#mainNav {
  background-color: #000;
}

#mainNav .navbar-toggler {
  right: 0;
  padding: 10px 20px;
  color: white;
  border: 0;
  background-color: #fed136;
  font-size: 20px;
}

#mainNav .navbar-brand {
  color: #fed136;
  font-family: 'Kaushan Script', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

#mainNav .navbar-brand.active,
#mainNav .navbar-brand:active,
#mainNav .navbar-brand:focus,
#mainNav .navbar-brand:hover {
  color: #fec503;
}

#mainNav .navbar-brand img.logo {
  width: 50px;
  height: 50px;
  -o-object-fit: contain;
  object-fit: contain;
}

#mainNav #navbarResponsive .navbar-nav .nav-item .nav-link {
  font-weight: 400;
  font-size: 0.7rem;
  padding: 0.75em 0;
  letter-spacing: 1px;
  color: white;
  font-weight: 600;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

#mainNav #navbarResponsive .navbar-nav .nav-item .nav-link.active,
#mainNav #navbarResponsive .navbar-nav .nav-item .nav-link:hover {
  color: #fed136;
}

#mainNav #navbarResponsive .navbar-nav .nav-item.social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  justify-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

#mainNav #navbarResponsive .navbar-nav .nav-item.social a.social-icons {
  background: white;
  height: 30px;
  width: 30px;
  line-height: 30px;
  padding: 0;
  border-radius: 100%;
  margin: 0 5px;
  text-align: center;
  cursor: pointer;
}

#mainNav #navbarResponsive .navbar-nav .nav-item.social a.social-icons i {
  color: black;
}

@media (min-width: 992px) {
  #mainNav {
    border: none;
    background: #000000;
  }

  #mainNav .navbar-brand {
    font-size: 1.5em;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }

  #mainNav .navbar-nav .nav-item .nav-link {
    padding: 1.1em 1em !important;
  }

  #mainNav.navbar-shrink {
    padding-top: 0;
    padding-bottom: 0;
    background-color: #000;
  }

  #mainNav.navbar-shrink .navbar-brand {
    font-size: 1.25em;
    padding: 12px 0;
  }
}

@media (max-width: 991px) {
  .navbar-collapse.collapse.show {
    padding-bottom: 12px;
  }
}

@media (max-width: 768px) {
  .video {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
  }

  .embed-container {
    padding-bottom: 56.25%;
    min-height: 1px;
  }

  .embed-container iframe,
  .embed-container object,
  .embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.language-selector select {
  background-color: #000;
  color: #ffe701;
  padding: 5px 10px;
  border-radius: 5px;
  position: fixed;
  top: 80px;
  right: 20px;
  border: none;
  outline: none;
  z-index: 500;
  -webkit-transition: all ease-in-out 1s;
  transition: all ease-in-out 1s;
  display: none;
}

.language-selector select option {
  color: #000;
  background: whitesmoke;
}

header.masthead {
  color: #000;
  background-image: url('../img/header-bg-min.jpg');
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
  padding-top: 6.1em;
}

header.masthead .intro-text {
  padding-top: 70px;
  padding-bottom: 100px;
  font-size: 1.2rem;
  font-weight: 400;
  color: #000;
}

header.masthead .intro-text .introduction {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

header.masthead .intro-text .introduction .img-holder img {
  width: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.features {
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.margin-bottom-xl {
  margin-bottom: 3em !important;
}

.features {
  max-width: 1024px;
  width: 100%;
  height: auto;
  object-fit: contain;
  margin: 0 auto 2.5em;
  -webkit-box-shadow: 1px 1px 5px 7px rgba(0,0,0,0.07);
  -moz-box-shadow: 1px 1px 5px 7px rgba(0,0,0,0.07);
  box-shadow: 1px 1px 5px 7px rgba(0,0,0,0.07);
  border-radius: 7px;
}

.feature-more-info {
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  text-align: center;
}

.feature-more-info h3.title {
  margin-bottom: 1em;
}

.feature-more-info .video-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 56.25% 0 0 0;
  position: relative;
}

@media (min-width: 900px) {
  .feature-more-info .video-container {
    padding: 44.25% 0 0 0;
  }
}

.feature-more-info .video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.feature-more-info .cta-buttons {
  text-align: center;
  margin: 3.1em auto 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: center;
  align-items: center;
  max-width: 420px;
}

.feature-more-info .cta-buttons .cta-button {
  background-color: #000;
  margin-bottom: 1em;
  padding: 0.5em 1em;
  border-radius: 5px;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 1rem;
  width: 314px !important;
  min-height: 50px;
  color: #fed136;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  font-family: 'Raleway-black';
}

@media (min-width: 500px) {
  .feature-more-info .cta-buttons .cta-button {
    font-size: 1.2rem;
    width: 364px !important;
  }
}

.feature-more-info .cta-buttons .cta-button span {
  margin: 0 0.2em;
}

.feature-more-info .press-release-link {
  color: black;
  font-size: 1rem;
  font-style: italic;
  font-weight: 700;
  display: block;
  margin: 2em auto 4em;
  max-width: 300px;
}

.feature-more-info .press-release-link i {
  margin-right: 3px;
}
.feature-more-info .cta-buttons .cta-button.know-more {
  width: 130px;
  font-weight: 600;
}

.feature-more-info .cta-buttons .white {
  color: white;
}

.feature-more-info .cta-buttons .white.normal {
  font-family: 'Raleway-Medium';
}

div p.no-limit-text {
  font-size: 40px;
  font-weight: 700;
  font-family: 'Raleway-Black';
  line-height: 0.95;
}

div p.no-limit-text span {
  font-size: 60px;
  display: block;
  font-weight: 700;
}

@media (min-width: 768px) {
  header.masthead .intro-text .introduction .img-holder {
    margin-bottom: 0;
  }
}

.font-black {
  font-family: 'Raleway-Black';
}

.font-700 {
  font-weight: 700;
}

.service-heading {
  margin: 15px 0;
  text-transform: none;
}

section#portfolio {
  background-color: #000000;
  padding: 20px;
  height: 400px;
  margin: 0 auto;
}

.video {
  position: relative;
  width: 90%;
  margin: 0 auto;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  margin-bottom: 50px;
}

.embed-container {
  position: relative;
  padding-bottom: 20rem;
  height: 500px;
  overflow: hidden;
  max-width: 100%;
  margin-top: -4.3rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.program {
  min-height: 650px;
  line-height: 10px;
  font-weight: 400;
  color: #000;
}

.showcase img {
  margin-bottom: 3px;
  width: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.description-dreambig p {
  margin-top: -5px;
  line-height: 1.3;
  font-size: 1.2rem;
  font-family: 'Raleway-Medium';
  font-weight: 400;
}

.program-title {
  color: #000;
  position: relative;
  top: -30px;
  left: 0;
  font-size: 20px;
  margin: 0 auto;
}

@media (min-width: 1600px) {
  section#portfolio {
    height: 26rem;
    margin: 0 auto;
  }
}

@media (max-width: 1096px) {
  .hide-img {
    display: none;
  }

  .program {
    height: auto;
  }

  .showcase {
    position: relative;
    top: 0;
    right: 0;
    margin-bottom: 3rem;
  }

  .showcase img {
    margin-bottom: 3px;
    height: auto;
    width: 100%;
  }
}

#editions {
  height: 66rem;
  background: #ffe701;
  width: 100%;
  position: relative;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

#editions .items {
  margin-top: -209px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

#editions .items .item {
  width: 21%;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex: 1 1 21%;
  flex: 1 1 21%;
  height: 255px;
  margin: 20px;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

#editions .items .item .content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.01);
  color: white;
  padding: 10px;
}

#editions .items .item .content a img.plus {
  width: 90px;
  height: 120px;
  -o-object-fit: contain;
  object-fit: contain;
  cursor: pointer;
}

#editions .items .item .content a {
  text-decoration: none;
}

#editions .items .item .content a img.dreambig {
  width: 100%;
  height: 115px;
  -o-object-fit: contain;
  object-fit: contain;
  cursor: pointer;
}

#editions .items .item .content .title {
  color: #ffe701;
  font-size: 22px;
  font-weight: 600;
}

#editions .items .item .content .subtitle {
  color: #ffe701;
  font-size: 15px;
}

#editions .items .item .content .actions-buttons {
  margin-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

#editions .items .item .content .actions-buttons .play {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

#editions .items .item .content .actions-buttons div.gallery {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
}

#editions .items .item .content .actions-buttons div button {
  width: 100px;
  border: none;
  background: transparent;
  cursor: pointer;
  outline: none;
}

#editions .items .item .content .actions-buttons div button img {
  width: 50px;
}

#editions .items .item.last-item {
  background-image: none;
  background: #000000;
}

.portfolio-modal {
  background: rgba(0, 0, 0, 0.6);
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.portfolio-modal .close-modal .close {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 15px;
  right: 50px;
  color: white;
  font-size: 60px;
  cursor: pointer;
  opacity: 1;
  z-index: 9000;
}

.portfolio-modal .modal-dialog .modal-content {
  background: none;
}

.portfolio-modal .modal-dialog .modal-content .container .row div {
  padding: 0;
}

.portfolio-modal .modal-dialog .modal-content .container .row div .modal-body {
  width: 100%;
  height: 85vh;
  background: #000000;
  border: 3px solid #ffe701;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  justify-items: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  padding-bottom: 10px;
}

.portfolio-modal .modal-dialog .modal-content .container .row div .modal-body .prev,
.portfolio-modal .modal-dialog .modal-content .container .row div .modal-body .next {
  position: absolute;
  color: white;
  font-size: 40px;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.085);
  width: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.portfolio-modal .modal-dialog .modal-content .container .row div .modal-body .prev.list,
.portfolio-modal .modal-dialog .modal-content .container .row div .modal-body .next.list {
  bottom: 7%;
  font-size: 30px;
}

.portfolio-modal .modal-dialog .modal-content .container .row div .modal-body .prev.prev,
.portfolio-modal .modal-dialog .modal-content .container .row div .modal-body .next.prev {
  left: 20px;
}

.portfolio-modal .modal-dialog .modal-content .container .row div .modal-body .prev.next,
.portfolio-modal .modal-dialog .modal-content .container .row div .modal-body .next.next {
  right: 20px;
}

.portfolio-modal .modal-dialog .modal-content .container .row div .modal-body .prev.image,
.portfolio-modal .modal-dialog .modal-content .container .row div .modal-body .next.image {
  top: 35%;
}

.portfolio-modal .modal-dialog .modal-content .container .row div .modal-body .current-image {
  width: 100%;
  height: 66vh;
  border: 1px solid rgba(0, 0, 0, 0.5);
  -o-object-fit: contain;
  object-fit: contain;
}

.portfolio-modal .modal-dialog .modal-content .container .row div .modal-body .gallery-images {
  height: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  margin-top: -20px;
  position: relative;
}

.portfolio-modal .modal-dialog .modal-content .container .row div .modal-body .gallery-images .gallery-navegation {
  position: absolute;
  top: 0;
  left: 0;
}

.portfolio-modal .modal-dialog .modal-content .container .row div .modal-body .gallery-images .img-gallery-thumbnail {
  width: 21%;
  max-height: 100px;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  margin: 0 1px;
  cursor: pointer;
  display: block;
}

.portfolio-modal .modal-dialog .modal-content .container .row div .modal-body .gallery-images .img-gallery-thumbnail.focus {
  -webkit-filter: blur(5px);
  /* Safari 6.0 - 9.0 */
  filter: blur(5px);
}

.portfolio-modal .modal-dialog .modal-content .container .row div .modal-body .gallery-images .not-yet {
  display: none;
}

.portfolio-video {
  background: rgba(0, 0, 0, 0.6);
}

.portfolio-video .close-modal-video {
  color: white;
  font-size: 60px;
  position: absolute;
  top: 0;
  right: 50px;
  cursor: pointer;
}

.portfolio-video .modal-dialog .modal-content {
  background-color: #000000;
  top: 20vh;
}

.portfolio-video .modal-dialog .modal-content .container .row .video {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  margin-bottom: 0;
  width: 100%;
}

.portfolio-video .modal-dialog .modal-content .container .row .video .embed-container {
  margin-top: 0;
}

#next-editions {
  width: 100%;
  color: #fed136;
  background: black;
  text-align: center;
  margin: 3rem auto 7rem;
  padding: 3rem;
}

.next-dreambig-section {
  margin: 0 auto;
  justify-items: center;
  align-content: center;
  align-items: center;
  max-width: 1440px;
}

.next-dreambig-section .item {
  min-height: 300px;
  margin: 10px;
  background-size: 100%;
  background-origin: content-box;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 24%;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex: 1 1 24%;
  flex: 1 1 24%;
}

.next-dreambig-section .item .content {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.next-dreambig-section .item .content span {
  color: #ffe701;
  font-weight: 700;
}

.know-more {
  width: 200px;
  height: 40px;
  font-size: 1.2rem;
  line-height: 40px;
}

.next-dreambig-section .item.active {
  display: block;
}

.next-dreambig-section .item.active::after {
  content: '';
  display: 'block';
  position: absolute;
  top: 0;
  z-index: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.next-dreambig-section .item.active .content {
  z-index: 11;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-direction: column;
}

.next-dreambig-section .item.active .content h4 {
  color: white;
}

.next-dreambig-section .item.active .content h5 {
  font-size: 1.4rem;
}

.next-dreambig-section .item.active .content a {
  background: #fec503;
  border: none;
  color: #000;
  padding: 10px 15px;
  font-size: 1.2rem;
  line-height: 20px;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  margin-top: 1rem;
}

.next-dreambig-section .item.active .content a:hover {
  font-weight: 700;
  color: rgba(0, 0, 0, 0.7);
}

@media (max-width: 900px) {
  .next-dreambig-section .item {
    width: 27%;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex: 1 1 27%;
    flex: 1 1 27%;
  }
}

@media (max-width: 827px) {
  .next-dreambig-section {
    flex-direction: column;
  }

  .next-dreambig-section .item {
    width: 60%;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex: 1 1 60%;
    flex: 1 1 60%;
  }
}

@media (max-width: 600px) {
  .next-dreambig-section .item {
    width: 100%;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
  }
}

@media (max-width: 420px) {
  #next-editions {
    padding: 1rem;
  }
}

@media (max-width: 400px) {
  .next-dreambig-section {
    width: 100%;
  }
}

@media (max-width: 1199px) {
  #editions {
    height: 84rem;
  }

  #editions .items {
    max-width: 880px;
  }

  .program {
    min-height: 690px;
  }
}

@media (max-width: 991px) {
  .program .container .row .showcase {
    margin-bottom: 30px;
  }
}

@media (max-width: 780px) {
  #editions {
    height: 144rem;
  }

  #editions .items .item {
    width: 30%;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 30%;
    flex: 1 1 30%;
  }
}

@media (max-width: 767px) {
  #editions .items {
    margin-top: -155px;
  }

  .container.next-editions .first-row .title {
    margin: 0 !important;
  }

  .container.next-editions .oviedo {
    margin: 50px auto;
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 800px;
    margin: 1.75rem auto;
  }
}

@media (max-width: 540px) {
  #editions {
    height: 143rem;
  }

  #editions .items {
    padding: 0;
  }

  #editions .items .item {
    margin: 5px;
    width: 45%;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 45%;
    flex: 1 1 45%;
    height: 280px;
  }
}

@media (max-width: 487px) {
  section.program {
    padding: 0px 0 !important;
  }

  #next-editions {
    margin-bottom: 12rem;
  }
}

@media (max-width: 410px) {
  #editions {
    height: 300rem;
  }

  #editions .items .item {
    margin: 20px;
    width: 90%;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 90%;
    flex: 1 1 90%;
    height: 280px;
  }
}

.timeline {
  position: relative;
  padding: 0;
  list-style: none;
}

.timeline:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 40px;
  width: 2px;
  margin-left: -1.5px;
  content: '';
  background-color: #e9ecef;
}

.timeline>li {
  position: relative;
  min-height: 50px;
  margin-bottom: 50px;
}

.timeline>li:after,
.timeline>li:before {
  display: table;
  content: ' ';
}

.timeline>li:after {
  clear: both;
}

.timeline>li .timeline-panel {
  position: relative;
  float: right;
  width: 100%;
  padding: 0 20px 0 100px;
  text-align: left;
}

.timeline>li .timeline-panel:before {
  right: auto;
  left: -15px;
  border-right-width: 15px;
  border-left-width: 0;
}

.timeline>li .timeline-panel:after {
  right: auto;
  left: -14px;
  border-right-width: 14px;
  border-left-width: 0;
}

.timeline>li .timeline-image {
  position: absolute;
  z-index: 100;
  left: 0;
  width: 80px;
  height: 80px;
  margin-left: 0;
  text-align: center;
  color: white;
  border: 7px solid #e9ecef;
  border-radius: 100%;
  background-color: #fed136;
}

.timeline>li .timeline-image h4 {
  font-size: 10px;
  line-height: 14px;
  margin-top: 12px;
}

.timeline>li.timeline-inverted>.timeline-panel {
  float: right;
  padding: 0 20px 0 100px;
  text-align: left;
}

.timeline>li.timeline-inverted>.timeline-panel:before {
  right: auto;
  left: -15px;
  border-right-width: 15px;
  border-left-width: 0;
}

.timeline>li.timeline-inverted>.timeline-panel:after {
  right: auto;
  left: -14px;
  border-right-width: 14px;
  border-left-width: 0;
}

.timeline>li:last-child {
  margin-bottom: 0;
}

.timeline .timeline-heading h4 {
  margin-top: 0;
  color: inherit;
}

.timeline .timeline-heading h4.subheading {
  text-transform: none;
}

.timeline .timeline-body>ul,
.timeline .timeline-body>p {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .timeline:before {
    left: 50%;
  }

  .timeline>li {
    min-height: 100px;
    margin-bottom: 100px;
  }

  .timeline>li .timeline-panel {
    float: left;
    width: 41%;
    padding: 0 20px 20px 30px;
    text-align: right;
  }

  .timeline>li .timeline-image {
    left: 50%;
    width: 100px;
    height: 100px;
    margin-left: -50px;
  }

  .timeline>li .timeline-image h4 {
    font-size: 13px;
    line-height: 18px;
    margin-top: 16px;
  }

  .timeline>li.timeline-inverted>.timeline-panel {
    float: right;
    padding: 0 30px 20px 20px;
    text-align: left;
  }
}

@media (min-width: 992px) {
  .timeline>li {
    min-height: 150px;
  }

  .timeline>li .timeline-panel {
    padding: 0 20px 20px;
  }

  .timeline>li .timeline-image {
    width: 150px;
    height: 150px;
    margin-left: -75px;
  }

  .timeline>li .timeline-image h4 {
    font-size: 18px;
    line-height: 26px;
    margin-top: 30px;
  }

  .timeline>li.timeline-inverted>.timeline-panel {
    padding: 0 20px 20px;
  }
}

@media (min-width: 1200px) {
  .timeline>li {
    min-height: 170px;
  }

  .timeline>li .timeline-panel {
    padding: 0 20px 20px 100px;
  }

  .timeline>li .timeline-image {
    width: 170px;
    height: 170px;
    margin-left: -85px;
  }

  .timeline>li .timeline-image h4 {
    margin-top: 40px;
  }

  .timeline>li.timeline-inverted>.timeline-panel {
    padding: 0 100px 20px 20px;
  }
}

.team-member {
  margin-bottom: 50px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
}

.team-member .profile {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.team-member img {
  width: 200px;
  height: 200px;
  -o-object-fit: contain;
  object-fit: contain;
}

.team-member h4 {
  padding: 10px 0;
  margin-bottom: 0;
  text-transform: none;
  color: #ffe701;
  font-style: italic;
  font-weight: 700;
  font-family: 'Raleway-Black';
  font-size: 2.2rem;
}

.team-member h4 a.team-social {
  text-decoration: none;
  margin-left: 10px;
  font-size: 1.8rem;
  color: black;
}

.team-member p {
  margin-top: 0;
  color: black;
  font-size: 1.2rem;
  font-family: 'Raleway-Medium';
  font-weight: 600;
  line-height: 1.3;
}

.promotor {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.promotor div {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.promotor div img {
  width: 100%;
}

/**
 * STYLE THE CONTACT FORM
 */
section#contact {
  background-color: #000;
  background-position: 100% 100%;
  background-size: contain;
  min-height: 80vh;
  color: white;
  padding: 100px 0 !important;
}

section#contact .form-control:focus {
  border-color: #777777;
  -webkit-box-shadow: none;
  box-shadow: none;
}

section#contact ::-webkit-input-placeholder {
  font-weight: 300;
  color: #777777;
}

section#contact :-moz-placeholder {
  font-weight: 300;
  color: #777777;
  /* Firefox 18- */
}

section#contact ::-moz-placeholder {
  font-weight: 300;
  color: #777777;
  /* Firefox 19+ */
}

section#contact :-ms-input-placeholder {
  font-weight: 300;
  color: #777777;
}

section#contact .text-danger {
  color: #e74c3c;
}

input,
select,
option {
  font-size: 20px;
  font-weight: 700;
  color: #777777;
}

::selection,
select:focus::-ms-value {
  background-color: gray;
  color: orange;
}

#sendMessageButton {
  background: #ffe701;
  cursor: pointer;
  color: #000;
  font-weight: 700;
  line-height: 0.25rem;
  font-size: 30px;
  font-style: italic;
  font-family: 'Raleway-Black';
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

#sendMessageButton:hover {
  color: rgba(0, 0, 0, 0.6);
  background: #fed136;
}

/**
*
* LOGOS OR SPONSORS
*/
.images-styles {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-line-pack: center;
  align-content: center;
}

.images {
  position: relative;
  float: left;
  width: 189px;
  height: 104px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
}

.extra-margin {
  margin-right: 15px;
}

@media (max-width: 768px) {
  #next-editions {
    height: auto;
  }

  .images-styles {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .extra-margin {
    margin-right: 0;
  }

  .images {
    position: relative;
    float: none;
    width: 189px;
    height: 104px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
    margin: 0 auto;
  }
}

#success {
  width: 100%;
}

#success div {
  width: 100%;
  height: auto;
}

.form-control {
  height: calc(3.25rem + 2px);
  border-radius: 0;
}

.input-required {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.input-required input,
.input-required button {
  margin-right: 5px;
  width: 97%;
  border-color: none;
}

.input-required span {
  font-size: 40px;
}

.organize-dreambig {
  font-family: 'Raleway-Medium';
  margin: 40px 0;
  text-align: left;
}

.organize-dreambig span {
  font-size: 3rem;
}

.icons-social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 50px;
}

.icons-social .nav-item.social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  justify-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.icons-social .nav-item.social a.social-icons {
  background: white;
  height: 30px;
  width: 30px;
  line-height: 30px;
  padding: 0;
  border-radius: 100%;
  margin: 0 5px;
  text-align: center;
  cursor: pointer;
}

.icons-social .nav-item.social a.social-icons i {
  color: black;
}

.icons-social img {
  width: 200px;
  margin-top: 20px;
  height: 100px;
  -o-object-fit: contain;
  object-fit: contain;
}

footer {
  padding: 25px 0;
  text-align: center;
}

footer span.copyright {
  font-size: 90%;
  line-height: 40px;
  text-transform: none;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

footer ul.quicklinks {
  font-size: 90%;
  line-height: 40px;
  margin-bottom: 0;
  text-transform: none;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

ul.social-buttons {
  margin-bottom: 0;
}

ul.social-buttons li a {
  font-size: 20px;
  line-height: 50px;
  display: block;
  width: 50px;
  height: 50px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  color: white;
  border-radius: 100%;
  outline: none;
  background-color: #212529;
}

ul.social-buttons li a:active,
ul.social-buttons li a:focus,
ul.social-buttons li a:hover {
  background-color: #fed136;
}